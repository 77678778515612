import React, { useEffect, useState, useRef } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';

const StyledBox = styled(Box)(() => ({
  '&.observerContainer': {
    overflow: 'hidden',
  },
}));

const LazyLoad = (props) => {
  const moreRef = useRef(null);
  const { children, skeleton, dataLoaded } = props;
  const { isCrawler } = useSelector((state) => state.globalData);
  const isLazyLoadEnabled = () => {
    if (isCrawler) return true;
    if (typeof window === 'undefined') return false;
    return !('IntersectionObserver' in window);
  };

  const [showChildren, setShowChildren] = useState(isLazyLoadEnabled());
  const scrollCallBack = (entries) => {
    if (
      (entries[0].intersectionRatio > 0 || entries[0].isIntersecting)
      && dataLoaded
    ) {
      setShowChildren(true);
    }
  };
  useEffect(() => {
    if ('IntersectionObserver' in window) {
      const scroll = new IntersectionObserver(scrollCallBack, {
        rootMargin: '0px',
        threshold: 0.1,
      });
      moreRef.current && scroll.observe(moreRef.current);
    }
    return () => {
      if (!isCrawler && 'IntersectionObserver' in window && dataLoaded) {
        moreRef.current && window.scroll.unobserve(moreRef.current);
      }
    };
  }, [showChildren, dataLoaded]);

  if (!showChildren) {
    return (
      <StyledBox ref={moreRef} className="observerContainer">
        {skeleton}
      </StyledBox>
    );
  }
  return children;
};
export { LazyLoad };
