import React from 'react';
import {
  CardContent, Grid, Box, Typography, IconButton,
} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import { useTranslation } from 'next-i18next';
import StyledBikeCard from 'views/common/components/UI/BikeCard/BikeCard.styles';
import Typography500 from 'views/common/components/UI/Typography500';
import { Typography700, ImgBox } from 'views/common/components';
import EagleRiderClubBlack from 'views/common/components/UI/Icons/EagleRiderClubBlack';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
// import { RibbonChip } from 'views/common/components/UI/RibbonChip/RibbonChip';

const BikeCard = (props) => {
  const { t } = useTranslation('fe_er_common_bike_card');
  const {
    image,
    price,
    soldout,
    hourlyPrice,
    tentative,
    valueDeal,
    vehicleModel,
    vehicleMake,
    region,
    owner,
    credit,
    url,
    availableCount,
    onClick,
    priceInCredits,
    specsPath = null,
    onMouseEnter,
    onMouseLeave,
  } = props;
  return (
    <StyledBikeCard
      className="BCcard"
      credit={credit}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}>
      {specsPath && (
        <IconButton className="BCBikeSpecs">
          <InfoOutlinedIcon />
          <Typography500
            href={specsPath}
            translate={false}
            target="_blank"
            component={NavigatorLink}
            className="BCBikeSpecsText">
            {t('fe_er_common_bike_card:view_bike_specs')}
          </Typography500>
        </IconButton>
      )}

      <NavigatorLink
        className="BClinkStyle"
        onClick={onClick}
        {...(url ? { href: url } : {})}>
        <ImgBox
          image={{
            src: image.src,
            alt: image.alt,
          }}
          priority={image.priority}
          imgHeight="200px"
        />

        <CardContent className="BCcontent">
          {soldout && (
            <Box className="BCcontentLabel BCsoldout">
              <ErrorOutlineIcon />
              <Typography500 component="span" className="BCfontXsmall">
                {t('fe_er_common_bike_card:soldout')}
              </Typography500>
            </Box>
          )}
          {!!availableCount && (
            <Box className="BCcontentLabel BCHurry">
              <WhatshotIcon />
              <Typography500 component="span">
                {`${t('fe_er_common_bike_card:availability', {
                  count: availableCount,
                })}`}
              </Typography500>
            </Box>
          )}
          {tentative && !availableCount && (
            <Box className="BCcontentLabel BCtentative">
              <ErrorOutlineIcon />
              <Typography500 component="span" className="BCfontXsmall">
                {`${t('fe_er_common_bike_card:limited_availability')} !`}
              </Typography500>
            </Box>
          )}
          {!availableCount && !soldout && !tentative && valueDeal && (
            <Box className="BCcontentLabel BCvalueDeal">
              <CardGiftcardIcon />
              <Typography500 component="span" className="BCfontXsmall">
                {t('fe_er_common_bike_card:value_deal')}
              </Typography500>
            </Box>
          )}

          <Grid
            container
            wrap="nowrap"
            justifyContent="space-between"
            {...(credit
              ? { align: 'center', spacing: '0', className: 'BCContentCont' }
              : { spacing: '2' })}>
            <Grid
              item
              {...(credit ? { sm: '12', className: 'BCContent' } : {})}>
              <Box>
                <Typography
                  variant="subtitle2"
                  component="p"
                  className="BCfontsmall">
                  {vehicleMake.name}
                </Typography>
                <Typography500
                  variant="h5"
                  component="p"
                  className="BCmodal drukFamily">
                  {vehicleModel.name}
                </Typography500>
              </Box>
              {credit && (
                <Box>
                  <Typography700
                    variant="body2"
                    component="span"
                    className="BCCredit">
                    {credit}
                    {' '}
                    {t('fe_er_common_bike_card:credit')}
                    <Typography
                      component="span"
                      variant="body2"
                      className="BCDay">
                      /
                      {t('fe_er_common_bike_card:day')}
                    </Typography>
                  </Typography700>
                  <Typography
                    variant="caption"
                    component="span"
                    className="BColdPrice">
                    {price}
                    /
                    {t('fe_er_common_bike_card:day')}
                  </Typography>
                </Box>
              )}
            </Grid>

            {!credit && (
              <Grid item className="BCnDailyPriceContainer">
                <Box className="BCnDailyPrice">
                  <Typography700 variant="subtitle1" component="span">
                    {price || hourlyPrice}
                  </Typography700>
                  <Typography variant="h6" component="span">
                    /
                    {price
                      ? t('fe_er_common_bike_card:day')
                      : t('fe_er_common_bike_card:hour')}
                  </Typography>
                </Box>
              </Grid>
            )}
          </Grid>
          {false && priceInCredits > 0 && (
            <Box className="BCClubPriceWrapper">
              <Box className=" BCClubPrice">
                <Typography700
                  color="primary"
                  variant="subtitle1"
                  className="BCText">
                  {priceInCredits}
                  {' '}
                  {t('fe_er_common_bike_card:credit')}
                  <Typography
                    color="primary"
                    component="span"
                    className="BCDayText">
                    /
                    {t('fe_er_common_bike_card:day')}
                  </Typography>
                </Typography700>
                {/* <RibbonChip label={t('fe_er_common_bike_card:club')} /> */}
              </Box>
            </Box>
          )}

          {owner && (
            <Box className="BCLocation" mt={2}>
              {region?.name && (
                <Box>
                  <LocationOnIcon />
                  <Typography
                    variant="subtitle2"
                    component="span"
                    className="BCfontsmall">
                    {region.name}
                  </Typography>
                </Box>
              )}
              <Box>
                <EagleRiderClubBlack />
                <Typography
                  variant="subtitle2"
                  component="span"
                  className="BCfontsmall BCDisplayName">
                  {owner.display_name}
                </Typography>
              </Box>
            </Box>
          )}
        </CardContent>
      </NavigatorLink>
    </StyledBikeCard>
  );
};

export { BikeCard };
