import { Card } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledCard = styled(Card)(({ theme, credit }) => ({
  '&.BCcard': {
    height: '99%',
    boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.16)',
    position: 'relative',

    '& .MuiCardActionArea-root': {
      height: '100%',
      '& .MuiCardActionArea-focusHighlight': {
        display: 'none',
      },
    },

    '& .BCBikeSpecs ': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(0.75, 0.5),
      borderRadius: theme.spacing(2),
      width: theme.spacing(4),
      height: theme.spacing(4),
      backgroundColor: 'transparent',
      color: theme.palette.grey[900],
      transition: 'border-color 0.5s, width 0.5s',
      border: '1px solid transparent',
      position: 'absolute',
      zIndex: 1,
      right: 0,
      margin: theme.spacing(1),
      '&:hover': {
        border: `1px solid ${theme.palette.grey['100']}`,
        width: `calc(100% - ${theme.spacing(2)})`,
        backgroundColor: theme.palette.grey.A000,

        '& .BCBikeSpecsText': {
          padding: theme.spacing(0, 2, 0, 1.25),
          textDecoration: 'underline',
        },
      },

      '& .BCBikeSpecsText': {
        transition: 'padding 0.5s, text-decoration 0.5s',
        fontSize: theme.typography.pxToRem(12),
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textTransform: 'uppercase',
        color: theme.palette.grey[900],
      },
    },

    '& .BClinkStyle': {
      height: '100%',
      color: 'initial',
    },
    '& .BCnDailyPrice': {
      padding: theme.spacing(1, 2.625),
      backgroundColor: theme.palette.grey['900'],
      color: theme.palette.common.fogWhite,
      textAlign: 'center',
      width: '100%',
    },
    '& .BCnDailyPriceContainer': {
      '&.MuiGrid-item': {
        paddingLeft: theme.spacing(0.5),
      },
    },
    '& .BCfontsmall': {
      fontSize: theme.typography.pxToRem(12),
    },
    '& .BCfontXsmall': {
      fontSize: theme.typography.pxToRem(10),
    },
    '& .BColdPrice': {
      textDecoration: 'line-through',
      color: theme.palette.grey[900],
      opacity: '0.7',
      paddingLeft: theme.spacing(2),
    },
    '& .BCLocation': {
      paddingRight: theme.spacing(2),
      //  maxWidth: 'calc(100% - 130px)',
      '& .BCfontsmall, & .BCDisplayName': {
        color: theme.palette.grey[400],
      },
      '& svg': {
        fontSize: theme.typography.pxToRem(25),
        paddingRight: theme.spacing(1),
        verticalAlign: 'middle',
        fill: theme.palette.common.black,
      },
    },
    '& .BCcontent': {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: 'calc(100% - 200px)', // 200px is image height
      paddingRight: credit ? theme.spacing(2) : theme.spacing(0),

      '&.MuiCardContent-root': {
        paddingTop: theme.spacing(3.25),
      },
      '& .BCcontentLabel': {
        position: 'absolute',
        right: '0',
        top: '0',
        fontWeight: 'bold',
        fontSize: theme.typography.pxToRem(10),
        paddingLeft: theme.typography.pxToRem(5),
        paddingRight: theme.typography.pxToRem(15),
        minWidth: theme.spacing(10.5),
        '&.BCsoldout': {
          backgroundColor: theme.palette.grey[50],
          color: theme.palette.common.black,
          textTransform: 'uppercase',
        },
        '&.BCHurry': {
          backgroundColor: theme.palette.error.main,
          color: theme.palette.common.fogWhite,
          display: 'flex',
          alignItems: 'center',
          '& .MuiTypography-root': {
            fontSize: theme.spacing(1.75),
          },
        },
        '&.BCtentative': {
          backgroundColor: theme.palette.warning.main,
          color: theme.palette.grey[900],
        },
        '&.BCvalueDeal': {
          backgroundColor: theme.palette.info.main,
          color: theme.palette.common.fogWhite,
        },
        '& svg': {
          fontSize: theme.typography.pxToRem(15),
          marginRight: theme.spacing(1),
          verticalAlign: 'middle',
        },
      },
      '& .BCClubPriceWrapper': {
        minWidth: theme.spacing(16),
        position: 'absolute',
        right: 0,
        top: theme.spacing(8.25),
        '& .BCClubPrice': {
          backgroundColor: theme.palette.primary.A000,
          padding: theme.spacing(1),
          overflow: 'hidden',
          position: 'relative',
          textAlign: 'center',
          '& .BCDayText': {
            fontSize: theme.typography.pxToRem(12),
            paddingLeft: theme.spacing(0.5),
          },
        },
      },
    },

    '& .BCContentCont': {
      height: '100%',
    },
    '& .BCContent': {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    '& .BCBYtext': {
      fontWeight: 300,
      fontSize: theme.spacing(1.5),
      color: theme.palette.secondary.dark,
    },
    '& .BCDisplayName': {
      fontWeight: 400,
      color: theme.palette.secondary.dark,
    },
    '& .BCCredit': {
      '& .BCDay': {
        fontWeight: 400,
      },
    },
  },
  '& .BCmodal': {
    fontSize: theme.spacing(2.25),
  },
}));

export default StyledCard;
