import React from 'react';
import {
  Typography, Grid, Box, Button,
} from '@mui/material';
import StyledBox from 'views/common/components/UI/EagleSharePromo/EagleSharePromo.styles';
import { useTranslation } from 'next-i18next';
import { ImgBox } from 'views/common/components';
import Typography500 from 'views/common/components/UI/Typography500';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink';

const EagleSharePromo = (props) => {
  const { path } = props;
  const { t } = useTranslation('fe_er_common_eagle_share_promo');
  const promo1 = {
    src: '/imgs/promo1.jpg',
    alt: 'eagle-rider-logo',
    title: t('fe_er_common_eagle_share_promo:card1_tite'),
    subtitle: t('fe_er_common_eagle_share_promo:card1_subTitle'),
  };
  const promo2 = {
    src: '/imgs/promo2.jpg',
    alt: 'eagle-rider-logo',
    title: t('fe_er_common_eagle_share_promo:card2_tite'),
    subtitle: t('fe_er_common_eagle_share_promo:card2_subTitle'),
  };
  const promo3 = {
    src: '/imgs/promo3.jpg',
    alt: 'eagle-rider-logo',
    title: t('fe_er_common_eagle_share_promo:card3_tite'),
    subtitle: t('fe_er_common_eagle_share_promo:card3_subTitle'),
  };
  return (
    <StyledBox>
      <NavigatorLink {...(path ? { href: path } : {})}>
        <Grid container justifyContent="space-between">
          <Grid item xl={4} lg={12} md={12} xs={12} className="ESPTitleGrid">
            <Typography500 className="ESPTitle" variant="h3" component="h2">
              {t('fe_er_common_eagle_share_promo:can’t_find_the_perfect_ride')}
            </Typography500>
            <Box className="showDESKTOP">
              <Button
                variant="text"
                color="secondary"
                endIcon={<ArrowForwardIcon />}
                className="ESPSeeMoreBtn">
                {t('fe_er_common_eagle_share_promo:see_more_es')}
              </Button>
            </Box>
          </Grid>
          <Grid item xl={7} lg={12} md={12} xs={12}>
            <Grid container columnSpacing={{ lg: 2.5, md: 1.5, sm: 1.5 }}>
              <Grid item lg={4} md={4} xs={6}>
                <Box className="ESPCard">
                  <ImgBox image={promo1} imgHeight="120px" />
                  <Box className="ESPCardText">
                    <Typography
                      variant="caption"
                      component="h4"
                      className="ESPCardTitle">
                      {promo1.title}
                    </Typography>
                    <Typography500
                      variant="caption"
                      component="h3"
                      className="ESPCardDesc">
                      {promo1.subtitle}
                    </Typography500>
                  </Box>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} xs={6}>
                <Box className="ESPCard">
                  <ImgBox image={promo2} imgHeight="120px" />
                  <Box className="ESPCardText">
                    <Typography
                      variant="caption"
                      component="h4"
                      className="ESPCardTitle">
                      {promo2.title}
                    </Typography>
                    <Typography500
                      variant="caption"
                      component="h3"
                      className="ESPCardDesc">
                      {promo2.subtitle}
                    </Typography500>
                  </Box>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} xs={6} className="hiddenMOBILE">
                <Box className="ESPCard">
                  <ImgBox image={promo3} imgHeight="120px" />
                  <Box className="ESPCardText">
                    <Typography
                      variant="caption"
                      component="h4"
                      className="ESPCardTitle">
                      {promo3.title}
                    </Typography>
                    <Typography500
                      variant="caption"
                      component="h3"
                      className="ESPCardDesc">
                      {promo3.subtitle}
                    </Typography500>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box className="showTABLET" textAlign="center">
          <Button
            variant="text"
            endIcon={<ArrowForwardIcon />}
            color="secondary"
            className="ESPSeeMoreBtn">
            {t('fe_er_common_eagle_share_promo:see_more_es')}
          </Button>
        </Box>
      </NavigatorLink>
    </StyledBox>
  );
};

export { EagleSharePromo };
