import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const EagleRiderClubBlack = (props) => (
  <SvgIcon
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="102"
    height="100"
    viewBox="0 0 102 100"
    fill="none">
    <g clipPath="url(#clip0_4809_7042)">
      <path
        d="M7.83881 66.1588L50.8621 90.9383L93.8853 66.1588V7.83881H7.83881V66.1588ZM50.8621 100L0 70.6295V0H101.744V70.6295L50.8621 100Z"
        fill="black"
      />
      <path
        d="M34.5028 34.3625C30.4932 33.8412 30.7939 32.5982 30.7939 32.5982L41.4996 30.7538C41.4996 30.7538 36.0064 34.5629 34.5028 34.3625ZM87.7907 52.4058C79.3304 49.4186 66.8204 39.8757 66.8204 39.8757C69.3324 39.8807 71.8426 39.7401 74.3384 39.4547C70.1739 37.5513 66.1536 35.3468 62.3095 32.8589C57.1772 29.2301 55.9342 26.4836 48.4964 25.5814C47.2206 25.4031 45.9352 25.3026 44.6472 25.2807C38.757 25.3204 33.0726 27.4521 28.6087 31.2951C27.3055 32.4178 26.4234 33.0593 26.4234 33.0593C23.4569 33.245 20.5329 33.8596 17.7426 34.8837C17.0129 35.0767 16.3338 35.4258 15.7523 35.9069C15.1708 36.3881 14.7007 36.9898 14.3745 37.6704C13.4523 39.575 14.6552 44.5469 14.6953 44.5068C22.3737 39.7755 36.0265 44.5068 36.9487 46.271C35.6656 42.8629 30.4531 39.3544 30.4531 39.3544C30.4531 39.3544 38.4723 41.1788 40.4771 48.2759C42.482 55.3729 33.2398 58.5004 32.6985 58.7811C37.19 58.2312 41.6427 57.4009 46.0305 56.2951C45.9674 57.3159 45.8131 58.3291 45.5694 59.3224C47.5742 58.3601 52.1251 56.1347 52.1251 56.1347C52.1251 56.1347 63.7931 58.34 68.344 58.6808C65.8042 57.1447 63.5069 55.2393 61.5277 53.0273C61.5277 53.0273 75.7819 56.1949 80.5734 57.0369C78.168 55.4467 76.0127 53.5076 74.178 51.2831C78.6515 52.0689 83.1891 52.4313 87.7306 52.3657"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_4809_7042">
        <rect width="101.744" height="100" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default EagleRiderClubBlack;
